<script setup lang="ts">
import type {CmsElementProductSlider} from "@shopware-pwa/composables-next";
import {useCmsElementConfig} from "@shopware-pwa/composables-next";
import {CmsSlot} from "@shopware-pwa/types";
import {SharedProductSliderConfig} from "~/types/SharedProductSliderConfig";

const props = defineProps<{
    content: CmsElementProductSlider;
}>();

const {getConfigValue} = useCmsElementConfig(props.content as CmsSlot & { config: CmsSlot["config"] });
const {algoliaSearch, getTopSellerIndexName} = useAlgolia();
const filtersStore = useFiltersStore();
const facetsStore = useFacetsStore();

const visibilityFilter = "visibilities.ca7a8b4761a844be94f9cbe265179d6a:30";
const streamId = props.content?.data?.streamId;
const globalFilters = props.content?.config?.globalFilters?.value;

const products = ref([]);
const streamFilter = ref("");
const filters = ref([visibilityFilter]);
const linkToCategory = ref("");

const autoplay = computed(() => getConfigValue("rotate"));
const title = computed(() => getConfigValue("title"));
const border = computed(() => getConfigValue("border"));

if (props.content?.config?.linkToCategory?.value) {
    linkToCategory.value = props.content?.config?.linkToCategory?.value
}

if (streamId) {
    streamFilter.value = 'streamIds:' + streamId
    filters.value.push(streamFilter.value);
}
const refinements = [
    'filterableProperties.Kategori',
    'filterableProperties.Alkohol',
    'filterableProperties.Certificering',
    'filterableProperties.Drue',
    'filterableProperties.Emballage',
    'filterableProperties.Land',
    'filterableProperties.Mærke',
    'filterableProperties.Region',
    'filterableProperties.Størrelse',
    'filterableProperties.Årgang'
];
const attributesToRetrieve = [
    'name',
    'subTitle',
    'isCloseout',
    'availableStock',
    'ratings',
    'url',
    'prices',
    'price',
    'properties',
    'images',
    'customFields',
    'tags',
    'customLabels',
]

const responseFields = [
    'hits',
    'nbHits',
    'facets',
];

const nbHits = ref(0);
const algoliaData = await algoliaSearch(props.content._uniqueIdentifier, getTopSellerIndexName(), '', {
    facetFilters: filters.value,
    facets: globalFilters ? refinements : null,
    attributesToRetrieve: attributesToRetrieve,
    responseFields: responseFields,
    hitsPerPage: 15,
});
nbHits.value = algoliaData.value.nbHits;

if (algoliaData.value?.hits?.length > 0) {
    products.value = algoliaData.value?.hits.filter((product) => product !== null);
}

async function getAlgoliaProducts (filterArray) {
    const requestOptions = {
        facetFilters: filterArray,
        attributesToRetrieve: attributesToRetrieve,
        responseFields: responseFields,
        hitsPerPage: 15,
    };
    if (globalFilters) {
        requestOptions['facets'] = refinements;
    }
    const searchResponse = await algoliaSearch(props.content._uniqueIdentifier, getTopSellerIndexName(), '', requestOptions);

    if (searchResponse.value?.nbHits) {
        nbHits.value = searchResponse.value.nbHits;
    }
    updateFiltersStore(searchResponse);

    return searchResponse.value;
}

const updateFiltersStore = (response) => {
    const facets = response.value?.facets;
    if (facets && streamId && !facetsStore.loadedStreamIds.includes(streamId)) {
        if (facetsStore.getAvailableFacets()) {
            for (const facet in facets) {
                for (const item in facets[facet]) {
                    facetsStore.addAvailableFacet(facet, item, facets[facet][item]);
                }
            }
        } else {
            facetsStore.setAvailableFacets(facets);
        }
        facetsStore.addStreamId(streamId);
    }
}

onMounted(() => {
    if (globalFilters) {
        filtersStore.$subscribe(async (mutation, state) => {
            filters.value = [visibilityFilter, streamFilter.value, ...state.activeFilters];
            algoliaData.value = await getAlgoliaProducts(filters.value);
            products.value = algoliaData.value?.hits.filter((product) => product !== null);
        });
    }
});

const config: SharedProductSliderConfig = {
    title: title.value,
    autoplay: autoplay.value,
    border: border.value,
    layoutType: getConfigValue("boxLayout"),
    displayMode: getConfigValue("displayMode"),
    elMinWidth: getConfigValue("elMinWidth"),
    link: linkToCategory.value,
    nbHits: nbHits.value
}
</script>
<template>
    <SharedProductSlider
        :products="products"
        :config="config"
    />
</template>
